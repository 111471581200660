import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusTransaction } from "enums/orders";
import { ApplicationRootState } from "types";
import { MemberOrderStateType } from "types/orders";

import { extraReducers } from "./orders.extraReducers";

export const initialState: MemberOrderStateType = {
  memberOrders: {
    deposit: {
      data: null,
      total: 0,
      isLoading: false,
      page: 1,
      pageSize: 10,
    },
    withdrawal: {
      data: null,
      total: 0,
      isLoading: false,
      page: 1,
      pageSize: 5,
    },
    rebate: {
      data: null,
      total: 0,
      isLoading: false,
    },
    reward: {
      data: null,
      total: 0,
      isLoading: false,
      page: 1,
      pageSize: 10,
    },
  },
  memberPromotions: {
    data: null,
    total: 0,
    isLoading: false,
  },
  statusTransaction: StatusTransaction.ALL,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateStatusTransaction(
      state: MemberOrderStateType,
      { payload }: PayloadAction<StatusTransaction>
    ) {
      state.statusTransaction = payload;
    },
    updateCurrentPageDeposit(
      state: MemberOrderStateType,
      { payload }: PayloadAction<number>
    ) {
      state.memberOrders.deposit.page = payload;
    },
    updateCurrentPageWithdrawal(
      state: MemberOrderStateType,
      { payload }: PayloadAction<number>
    ) {
      state.memberOrders.withdrawal.page = payload;
    },
    resetDepositOrders(state: MemberOrderStateType) {
      state.memberOrders.deposit.total = 0;
      state.memberOrders.deposit.page = 1;
      state.memberOrders.deposit.data = null;
    },
    resetWithdrawalOrders(state: MemberOrderStateType) {
      state.memberOrders.withdrawal.total = 0;
      state.memberOrders.withdrawal.page = 1;
      state.memberOrders.withdrawal.data = null;
    },
    resetRebateOrders(state: MemberOrderStateType) {
      state.memberOrders.rebate.total = 0;
      state.memberOrders.rebate.data = null;
    },
    resetRewardOrders(state: MemberOrderStateType) {
      state.memberOrders.reward.total = 0;
      state.memberOrders.reward.data = null;
    },
    resetPromotion(state: MemberOrderStateType) {
      state.memberPromotions.data = null;
      state.memberPromotions.isLoading = false;
      state.memberPromotions.total = 0;
    },
    resetOrders: () => initialState,
  },
  extraReducers,
});

export const selectMemberOrder = (state: ApplicationRootState) =>
  state.orders.memberOrders;

export const selectDepositOrders = (state: ApplicationRootState) =>
  state.orders.memberOrders.deposit;

export const selectWithdrawalOrders = (state: ApplicationRootState) =>
  state.orders.memberOrders.withdrawal;

export const selectRebateOrders = (state: ApplicationRootState) =>
  state.orders.memberOrders.rebate;

export const selectMemberPromotions = (state: ApplicationRootState) =>
  state.orders.memberPromotions;

export const selectRewardOrders = (state: ApplicationRootState) =>
  state.orders.memberOrders.reward;

export const selectStatusTransaction = (state: ApplicationRootState) =>
  state.orders.statusTransaction;

export const {
  updateStatusTransaction,
  updateCurrentPageDeposit,
  updateCurrentPageWithdrawal,
  resetDepositOrders,
  resetWithdrawalOrders,
  resetOrders,
  resetRebateOrders,
  resetRewardOrders,
  resetPromotion,
} = ordersSlice.actions;

export default ordersSlice.reducer;
