import CrushGame from "assets/Common/images/game/crush-game.svg";
import CrushGameActive from "assets/Common/images/game/crush-game-active.svg";
import IconHeader_1 from "assets/Desktop/images/homepage/header-swiper-1.png";
import IconHeader_2 from "assets/Desktop/images/homepage/header-swiper-2.png";
import IconHeader_3 from "assets/Desktop/images/homepage/header-swiper-3.png";
import IconHeader_4 from "assets/Desktop/images/homepage/header-swiper-4.png";
import IconHeader_5 from "assets/Desktop/images/homepage/header-swiper-5.png";
import IconHeaderActive_1 from "assets/Desktop/images/homepage/header-swiper-active-1.png";
import IconHeaderActive_2 from "assets/Desktop/images/homepage/header-swiper-active-2.png";
import IconHeaderActive_3 from "assets/Desktop/images/homepage/header-swiper-active-3.png";
import IconHeaderActive_4 from "assets/Desktop/images/homepage/header-swiper-active-4.png";
import { BoardActive } from "assets/Mobile/icons";

import { TypeCategorySwiper } from "./type";

export const LIST_CATEGORY_SWIPER: TypeCategorySwiper[] = [
  {
    title: "Live Casino",
    key: 1,
    image: IconHeader_1,
    imageActive: IconHeaderActive_1,
  },
  {
    title: "Sports",
    key: 2,
    image: IconHeader_2,
    imageActive: IconHeaderActive_2,
  },
  {
    title: "Slots",
    key: 3,
    image: IconHeader_3,
    imageActive: IconHeaderActive_3,
  },
  {
    title: "Fishing",
    key: 4,
    image: IconHeader_4,
    imageActive: IconHeaderActive_4,
  },
  {
    title: "Board Games",
    key: 5,
    image: IconHeader_5,
    imageActive: BoardActive,
  },
  {
    title: "Crush Games",
    key: 6,
    image: CrushGame,
    imageActive: CrushGameActive,
  },
];
