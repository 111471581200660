import {
  ImageGameDemo_1,
  ImageGameDemo_2,
  ImageGameDemo_3,
  ImageGameDemo_4,
  ImageGameDemo_5,
  ImageGameDemo_6,
} from "assets/temporary/images";
import { SubGameType } from "types/game";

export const LIST_GAME_DEMO: SubGameType[] = [
  {
    featured: "1",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: ImageGameDemo_1,
    provider_code: "",
    type: "",
  },
  {
    featured: "1",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: ImageGameDemo_2,
    provider_code: "",
    type: "",
  },
  {
    featured: "1",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: ImageGameDemo_3,
    provider_code: "",
    type: "",
  },
];

export const LIST_CATEGORY_GAME_DEMO: SubGameType[] = [
  {
    featured: "1",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: ImageGameDemo_4,
    provider_code: "",
    type: "",
  },
  {
    featured: "1",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: ImageGameDemo_5,
    provider_code: "",
    type: "",
  },
  {
    featured: "1",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: ImageGameDemo_6,
    provider_code: "",
    type: "",
  },
];

export const LIST_CODE_GAME_ARCADE = {
  SPRIBE: "SPRI",
  AVIATRIX: "AVIA",
};
