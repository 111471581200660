import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const TermAndConditionsNewStyle = {
  section: css`
    padding: 15px;
    margin-bottom: 15px;
    padding-top: 75px;

    ${media.md} {
      flex: 0 0 100%;
      max-width: 100%;
      padding-top: 35px;
    }
  `,
  main: css`
    display: block;
  `,
  titleBar: css`
    color: ${theme.colors.darkGreen};
    font-size: 30px;
    text-align: center;
    border-radius: 3px;
    ${media.md} {
      font-size: 36px;
    }
  `,
  article: css`
    display: block;

    .entry-content {
      margin: 1.5em 0 0;
      padding: 0 15px;
      counter-reset: footnotes;

      &::before {
        content: "";
        display: table;
        table-layout: fixed;
        box-sizing: border-box;
      }
    }
    .fourHundered {
      font-weight: 400;
    }
    p {
      margin-top: 0;
      margin-bottom: 1.5em;
    }
    ol,
    ul {
      padding-inline-start: 40px;
    }
    dl,
    ol,
    ul {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ol,
    ul {
      box-sizing: border-box;
    }
    b,
    strong {
      font-weight: bolder;
    }

    ol > li > b {
      background: linear-gradient(194deg, #1fa94d 9.53%, #298548 90.21%);
      color: ${theme.colors.white};
      padding: 4px 8px;
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);
      border-radius: 50px;
    }

    .listNone {
      list-style-type: none;
      b {
        background: unset;
        color: unset;
        box-shadow: unset;
        border-radius: unset;
      }
    }
  `,
};
