export const media = {
  "2sm": "@media (max-width: 375px)",
  sm: "@media (max-width: 576px)",
  md: "@media (max-width: 768px)",
  lg: "@media (max-width: 992px)",
  xl: "@media (max-width: 1200px)",
  xxl: "@media (max-width: 1536px)",
  "3xl": "@media (max-width: 1680px)",
  noneHover: "@media (pointer: coarse)",
};
