/** @jsxImportSource @emotion/react */

import HelmetCustom from "components/Common/HelmetCustom";
import { useTranslation } from "react-i18next";

import { TermAndConditionsNewStyle } from "./index.style";

const TermAndConditionsNewPage = () => {
  const { t } = useTranslation(["tc"]);

  return (
    <section css={TermAndConditionsNewStyle.section}>
      <HelmetCustom />
      <main css={TermAndConditionsNewStyle.main}>
        <div css={TermAndConditionsNewStyle.titleBar}>{t("tandc")}</div>
        <article css={TermAndConditionsNewStyle.article}>
          <div className="entry-content">
            <p>
              <span className="fourHundered">{t("LastUpdated")}: </span>
              <span className="fourHundered">{t("18September2023")}</span>
            </p>
            <ol>
              <li>
                <b>{t("Introduction")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content1")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content2")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content3")}</span>
            </p>
            <ol start={2}>
              <li>
                <b>{t("GeneralTerms")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content4")}</span>
            </p>
            <ol start={3}>
              <li>
                <b>{t("YourObligations")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content5")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content6")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content7")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content8")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content9")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content10")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content11")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content12")}</span>
            </p>
            <ol start={4}>
              <li>
                <b>{t("RestrictedUse")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content18")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content19")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content20")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content21")}</span>
            </p>
            <ul>
              <li>
                <span className="fourHundered"> {t("Austria")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Australia")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Aruba")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Bonaire")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Curacao")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("France")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Netherlands")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Saba")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Statia")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("St. Maarten")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Singapore")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("Spain")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("The United Kingdom")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("United States")}</span>
              </li>
              <li>
                <span className="fourHundered">{t("content22")}</span>
              </li>
            </ul>
            <p>
              <span className="fourHundered">{t("content23")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content24")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content25")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content26")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content27")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content28")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content29")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content30")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content31")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content32")}</span>
            </p>
            <ol start={5}>
              <li>
                <b>{t("Registration")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content33")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content34")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content35")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content36")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content37")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content38")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content39")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content40")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content41")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content42")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content43")}</span>
            </p>
            <ol start={6}>
              <li>
                <b>{t("YourAccount")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content44")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content45")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content46")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content47")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content48")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content49")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content50")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content51")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content52")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content53")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content54")}</span>
            </p>
            <ol start={7}>
              <li>
                <b>{t("InactiveAccounts")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content55")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content56")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content57")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content58")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content59")}</span>
            </p>

            <ol start={8}>
              <li>
                <b>{t("DepositofFunds")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content60")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content61")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content62")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content63")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content64")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content65")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content66")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content67")}</span>
            </p>
            <ol start={9}>
              <li>
                <b>{t("DepositofFunds")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content68")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content69")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content70")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content71")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content72")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content73")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content74")}</span>
            </p>
            <ol start={10}>
              <li>
                <b>{t("PaymentTransactionsandProcessors")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content75")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content76")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content77")}</span>
            </p>
            <ol start={11}>
              <li>
                <b>{t("Errors")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content78")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content79")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content80")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content81")}</span>
            </p>
            <ol start={12}>
              <li>
                <b> {t("title12")}</b>
              </li>
            </ol>
            <br />
            <br />
            <ol className="listNone">
              <li>
                <span className="fourHundered">{t("content82")}</span>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content83")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content84")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content85")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content86")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content87")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content88")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content89")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content90")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content9")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content92")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content93")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content94")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content95")}</span>
            </p>
            <ol start={13}>
              <li>
                <b>{t("CommunicationsandNotices")}</b>
              </li>
            </ol>

            <p>
              <span className="fourHundered">{t("content96")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content97")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content98")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content99")}</span>
            </p>
            <ol start={14}>
              <li>
                <b>{t("MattersBeyondOurControl")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content100")}</span>
            </p>
            <ol start={15}>
              <li>
                <b>{t("Liability")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content101")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content102")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content103")}</span>
            </p>
            <ol start={16}>
              <li>
                <b>{t("GamblingByThoseUnderAge")}</b>
              </li>
            </ol>

            <p>
              <span className="fourHundered">{t("content104")}</span>
            </p>
            <ul>
              <li>
                <span className="fourHundered"> {t("content105")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("content106")}</span>
              </li>
              <li>
                <span className="fourHundered"> {t("content107")}</span>
              </li>
            </ul>
            <p>
              <span className="fourHundered">{t("content108")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content109")}</span>
            </p>
            <ol start={17}>
              <li>
                <b>{t("GamblingByThoseUnderAge")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content110")}</span>
            </p>
            <ol start={18}>
              <li>
                <b>{t("IntellectualProperty")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content111")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content112")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content113")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content114")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content115")}</span>
            </p>
            <ol start={19}>
              <li>
                <b>{t("YourLicence")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content116")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content117")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content118")}</span>
            </p>
            <ol start={20}>
              <li>
                <b>{t("YourConductandSafety")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content119")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content120")}</span>
              <span className="fourHundered">{t("content121")}</span>
              <span className="fourHundered">{t("content122")}</span>
            </p>
            <ol>
              <li>
                <span className="fourHundered">{t("content122")}</span>
              </li>
              <li>
                <span className="fourHundered">{t("content123")}</span>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content124")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content125")}</span>
            </p>
            <ol>
              <li>
                <span className="fourHundered">{t("content126")}</span>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content127")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content128")}</span>
            </p>
            <ol start={21}>
              <li>
                <b>{t("LinkstoOtherWebsites")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content129")}</span>
            </p>
            <ol start={22}>
              <li>
                <b>{t("Complaints")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content130")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content131")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content132")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content133")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content134")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content135")}</span>
            </p>
            <ol start={23}>
              <li>
                <b>{t("Assignment")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content136")}</span>
            </p>
            <ol start={24}>
              <li>
                <b>{t("Severability")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content137")}</span>
            </p>
            <ol start={25}>
              <li>
                <b>{t("BreachofTheseTerms")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content138")}</span>
            </p>
            <ol className="listNone">
              <li>
                <b>{t("GoverningLawandJurisdiction")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content139")}</span>
            </p>
            <ol className="listNone">
              <li>
                <b>{t("RestrictedCountry")}</b>
              </li>
            </ol>
            <p>
              <span className="fourHundered">{t("content140")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content141")}</span>
            </p>
            <p>&nbsp;</p>
            <ol start={26}>
              <li>
                <b>{t("GeneralProvisions")}</b>
              </li>
            </ol>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content142") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content143") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content144") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content145") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content146") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content147") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content148") }}
              ></span>
            </p>
            <p>
              <span
                className="fourHundered"
                dangerouslySetInnerHTML={{ __html: t("content149") }}
              ></span>
            </p>
            <p>
              <b>{t("BettingRules")}</b>
            </p>
            <p>
              <span className="fourHundered">{t("content150")}</span>
            </p>
            <p>
              <b>{t("CasinoRules")}</b>
            </p>
            <p>
              <span className="fourHundered">{t("content151")}</span>
            </p>
            <p>
              <span className="fourHundered">{t("content152")}</span>
            </p>
            <p>
              <b>{t("content153")}</b>
            </p>
            <p>
              <span className="fourHundered">{t("content154")}</span>
            </p>
          </div>
        </article>
      </main>
    </section>
  );
};
export default TermAndConditionsNewPage;
