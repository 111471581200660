import { css, keyframes } from "@emotion/react";
import InfoBgImage from "assets/Desktop/images/homepage/info-bg.png";
import notificationBg from "assets/Desktop/images/homepage/notification-background.png";
import SwiperCategoryBg from "assets/Desktop/images/homepage/swiper-categoryt-bg.png";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

const scrollingText = keyframes`
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const SEOContent = {
  wrapper: css`
    ${media.sm} {
      h1 {
        font-size: 24px;
      }
      h2 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
    }
    padding: 20px;
    margin-top: 20px;
    position: relative;
    .blue {
      height: 300px;
      overflow: hidden;
      padding-bottom: 0px;
      mask-image: linear-gradient(to top, transparent, black);
      -webkit-mask-image: linear-gradient(to top, transparent, black);
    }
    .showmore {
      position: absolute;
      bottom: 10px;
      left: calc(50% - 50px);
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 14px;
      background: #24984c;
      color: white;
      cursor: pointer;
    }
  `,
};

export const HomePageStyle = {
  wrapper: {
    index: css`
      min-width: 1200px;
      padding-top: 75px;
    `,

    describe: css`
      text-align: center;
      justify-items: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      color: ${theme.colors.lightText};

      max-width: 984px;
      margin: 0 auto;

      ${media.xxl} {
        font-size: 16px;
      }
    `,
    title: css`
      color: ${theme.colors.darkGreen};

      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 43px;
      text-transform: uppercase;

      ${media.xxl} {
        font-size: 30px;
      }
    `,
  },

  notification: {
    index: css`
      min-width: 1200px;
    `,
    item: css`
      background-image: url(${notificationBg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.5625rem;
      border-radius: 1.5rem;
      padding: 0.4rem;
      border-radius: 1.5rem;
      box-shadow: 0 4px 12px 0 rgba(107, 123, 168, 0.25);

      overflow: hidden;
    `,
    img: css`
      width: unset;
      height: auto;
    `,
    text: css`
      overflow: hidden;
      width: 100%;
      margin: 0 10px;
      height: 100%;
      display: flex;
      span {
        overflow: hidden;
        white-space: nowrap;
        animation: ${scrollingText} 45s linear infinite;

        display: flex;
        min-width: max-content;
        gap: 200px;
        .width-text {
          min-width: max-content;
        }
        align-items: center;
        width: 100%;
        color: ${theme.colors.text.darkGreen};
      }
    `,
  },

  overview: {
    index: css`
      position: relative;
      padding-top: 80px;
      .slick-list {
        z-index: 98;
      }
    `,
    categorySwiper: css`
      padding-top: 60px;

      padding-bottom: 1rem;
      .slick-list {
        height: auto;
        display: flex;
        align-items: center;

        width: 100%;
        justify-content: space-around;
        background-image: url(${SwiperCategoryBg});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        list-style: none;
        padding: 0.5rem;

        .slick-track {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .slick-slide {
          width: unset !important;
        }

        .slick-current {
          background: ${theme.colors.gradient[1]};
          border-radius: 24px;

          img {
            &:first-of-type {
              display: none !important;
            }
            &:last-of-type {
              display: block !important;
            }
          }
          p {
            color: #fff;
          }
        }
      }
    `,
    gamesSwiper: css`
      display: flex;
      align-items: center;
      .slick-arrow {
        cursor: pointer;
        pointer-events: visible;
        background-color: rgba(41, 133, 72, 0.65);
        transition: all 0.3s ease-in-out;
        width: 55px;
        height: 120px;
      }
      .slick-prev {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .slick-next {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        &::before {
        }
      }

      .slick-track {
        padding: 3rem 0 6rem;

        .slick-slide {
          transition: all 0.3s ease-in-out;
          padding: 0 0.75rem;
        }
        padding: 3rem 0 6rem;
        .slick-active {
          opacity: 0.5;
        }
        .slick-current {
          opacity: 1;
          img {
            &:last-of-type {
              transform: scale(1.25);
              transition: all 0.25s ease-in-out;
            }
          }
        }
      }
    `,
  },

  imgBottom: css`
    position: absolute;
    bottom: -15px;
    right: 50%;
    transform: translateX(50%);
    img {
      max-width: 495px;
    }
  `,

  info: {
    index: css`
      padding-top: 60px;
    `,
    btnLink: css`
      background: linear-gradient(254deg, #1fa94d 9.53%, #298548 90.21%);
      color: #fff;
      text-decoration: none;
      padding: 6px 12px;
      font-size: 16px;
      box-shadow: 0 4px 12px 0 rgba(107, 123, 168, 0.25);
      font-weight: 500;
      border-radius: 30px;
      line-height: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center;
      max-width: 150px;
      height: 45px;
      margin: 0 auto;
      border: none;
      cursor: pointer;
      width: 100%;

      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    `,
    header: {
      index: css`
        padding-bottom: 40px;
        padding-top: 60px;
      `,
    },
    bottom: {
      index: css`
        position: relative !important;
      `,
      image: css`
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        img {
          max-width: 550px;
        }
      `,
      content: css`
        display: flex;
        justify-content: end;
        align-items: center;
        position: relative;
        width: 55.4375rem;
        height: 30rem;
        background-image: url(${InfoBgImage});
        background-repeat: no-repeat;
        background-position: bottom;
        margin-left: auto;
        padding: 1rem;

        border-radius: 1.5rem;
        box-shadow: 0 4px 12px 0 rgba(107, 123, 168, 0.25);
        overflow: hidden;
      `,
    },
  },

  cardInner: css`
    width: 100%;
    max-width: 63%;
    height: 73%;
    h2 {
      max-width: 70%;
      color: ${theme.colors.darkGreen};
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
      line-height: 1.2;
      margin-bottom: 16px;
    }
    p {
      max-height: 85%;
      color: ${theme.colors.lightText};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }
  `,
  sectionQr: css`
    max-width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  `,
  blockQr: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    img {
      width: 84px;
    }
  `,
  blockIconQr: css`
    display: flex;
    height: 2rem;
    padding: 0rem 1.125rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border-radius: 1rem;
    background: #fff;
    width: max-content;
    img {
      width: 24px !important;
      height: 24px !important;
    }
    span {
      color: ${theme.colors.lightText};
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3125rem;
    }
  `,

  service: {
    header: css`
      padding-top: 160px;

      ${media.xxl} {
        padding-top: 80px;
      }
    `,
    content: css`
      padding-top: 148px;
      gap: 24px;
      display: grid;
    `,
    grid_4: css`
      grid-template-columns: repeat(4, 1fr);
    `,
    grid_2: css`
      grid-template-columns: repeat(2, 1fr);
    `,
  },
};
