/** @jsxImportSource @emotion/react */

import { ReactComponent as BrandSVG } from "assets/Common/icons/brand.svg";
import HelmetCustom from "components/Common/HelmetCustom";
import { dataAffiliate } from "contants/dataAffiliatePage";
import { useAppDispatch } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { ModalType, setOpenModal } from "store/modal";

import { AffiliatePageStyle } from "./index.style";

const AffiliatePage = () => {
  const { t } = useTranslation(["affiliatePage", "header"]);
  const dispatch = useAppDispatch();

  const totalDeposit = dataAffiliate.reduce(
    (total, item) => total + Number(item.deposit),
    // (total, item) => 0,
    0
  );

  const totalCashout = dataAffiliate.reduce(
    (total, item) => total + Number(item.cashout),
    0
  );

  const totalWinLoss = dataAffiliate.reduce(
    (total, item) => total + Number(item.winLoss),
    0
  );

  const totalPlatformFee = dataAffiliate.reduce(
    (total, item) => total + Number(item.platformFee),
    // (total, item) => 5130,
    0
  );
  const totalBonus = dataAffiliate.reduce(
    (total, item) => total + Number(item.bonus),
    0
  );
  const totalPaymentFee = dataAffiliate.reduce(
    (total, item) => total + Number(item.paymentFee),
    0
  );

  const totalComm = dataAffiliate.reduce(
    (total, item) => total + Number(item.commission),
    0
  );

  function formatNumberWithCommas(number: string | number) {
    const intNum = typeof number === "string" ? Number(number) : number;
    return intNum.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }

  return (
    <div css={AffiliatePageStyle.section}>
      <HelmetCustom />
      <div css={AffiliatePageStyle.wrapper}>
        <h1 className="title">{t("affiliatePage:title")}</h1>
        <div css={AffiliatePageStyle.wrapInfo}>
          <div className="row-info">
            <div className="wrap-item">
              <h4 className="subtitle">
                {t("affiliatePage:PaymentFee_title")}:
              </h4>
              <p className="desc">{t("affiliatePage:PaymentFee_text")}</p>
              <p className="desc">+</p>
              <p className="desc">{t("affiliatePage:PaymentFee_text1")}</p>
            </div>
            <div className="wrap-item">
              <h4 className="subtitle">
                {t("affiliatePage:PlatformFeeTitle")}:
              </h4>
              <p className="desc">{t("affiliatePage:PlatformFeeText")}</p>
            </div>
          </div>
          <div className="wrap-item">
            <h4 className="subtitle">{t("affiliatePage:Bonus_title")}:</h4>
            <p className="desc">
              {t("affiliatePage:Bonus_text1")} +{" "}
              {t("affiliatePage:Bonus_text2")}
            </p>
          </div>
          <div className="wrap-item">
            <h4 className="subtitle">{t("affiliatePage:NetProfit_title")}</h4>
            <p className="desc">{t("affiliatePage:NetProfit_text")}</p>
          </div>
        </div>
        <div css={AffiliatePageStyle.wrapBtnReg}>
          <button
            className="btnRegisterAff"
            onClick={() =>
              dispatch(setOpenModal("affiliateRegister" as ModalType))
            }
          >
            <BrandSVG fill="#1fa94d" />
            {t("affiliatePage:AffReg")}
          </button>
          <a
            href="https://b568.pos8.co/"
            target="_blank"
            className="href-link"
            rel="noreferrer"
          >
            {t("affiliatePage:AffLogin")}
          </a>
        </div>
        <div css={AffiliatePageStyle.wrapTable}>
          <h2 className="title">{t("affiliatePage:tablet_title")}</h2>
          <div className="table-inner">
            <table>
              <thead>
                <tr>
                  <th>{t("affiliatePage:thead_1")}</th>
                  <th>{t("affiliatePage:thead_2")}</th>
                  <th>{t("affiliatePage:thead_9")}</th>
                  <th>{t("affiliatePage:thead_4")}</th>
                  <th>{t("affiliatePage:thead_5")}</th>
                  <th>{t("affiliatePage:thead_6")}</th>
                  <th>{t("affiliatePage:thead_7")}</th>
                  <th>{t("affiliatePage:thead_8")}</th>
                </tr>
              </thead>
              <tbody>
                {dataAffiliate.map((item, index) => (
                  <tr key={index}>
                    <td>{t(item.player)}</td>
                    <td className={`${Number(item.deposit) < 0 ? "down" : ""}`}>
                      {formatNumberWithCommas(item.deposit) + ".00"}
                    </td>
                    <td className={`${Number(item.cashout) < 0 ? "down" : ""}`}>
                      {formatNumberWithCommas(item.cashout)}
                    </td>
                    <td className={`${Number(item.winLoss) < 0 ? "down" : ""}`}>
                      {formatNumberWithCommas(item.winLoss) + ".00"}
                    </td>
                    <td
                      className={`${
                        Number(item.platformFee) < 0 ? "down" : ""
                      }`}
                    >
                      {formatNumberWithCommas(item.platformFee)}
                    </td>
                    <td className={`${Number(item.bonus) < 0 ? "down" : ""}`}>
                      {formatNumberWithCommas(item.bonus)}
                    </td>
                    <td
                      className={`${Number(item.paymentFee) < 0 ? "down" : ""}`}
                    >
                      {formatNumberWithCommas(item.paymentFee)}
                    </td>
                    <td
                      className={`${Number(item.commission) < 0 ? "down" : ""}`}
                    >
                      {formatNumberWithCommas(item.commission)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>{t("affiliatePage:table_total")}</th>
                  <th>{formatNumberWithCommas(totalDeposit) + ".00"}</th>
                  <th>{formatNumberWithCommas(totalCashout)}</th>
                  <th>{formatNumberWithCommas(totalWinLoss) + ".00"}</th>
                  <th>{formatNumberWithCommas(totalPlatformFee)}</th>
                  <th>{formatNumberWithCommas(totalBonus)}</th>
                  <th>{formatNumberWithCommas(totalPaymentFee)}</th>
                  <th>{formatNumberWithCommas(totalComm)}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="group-notice">
            <div className="notice-title">{t("affiliatePage:noteTitle")}:</div>
            <p>- {t("affiliatePage:noteItem_1")}</p>
            <p>- {t("affiliatePage:noteItem_2")}</p>
            <p>- {t("affiliatePage:noteItem_3")}</p>
          </div>
        </div>
        {/* <div css={AffiliatePageStyle.wrapTable}>
          <h2 className="title">{t("levelAff:title")}</h2>
          <div className="table-inner">
            <table>
              <thead>
                <tr>
                  <th>{t("levelAff:thead_1")}</th>
                  <th>{t("levelAff:thead_5")}</th>
                  <th>{t("levelAff:thead_3")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    {"\u2265"} {"1"}
                  </td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    {"\u2265"} {"5"}
                  </td>
                  <td>40%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    {"\u2265"} {"10"}
                  </td>
                  <td>50%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="note">* {t("levelAff:noteBottom")}</div>
          <div className="note">* {t("levelAff:bonus_note_1")}</div>
          <div className="note">* {t("levelAff:bonus_note_2")}</div>
        </div> */}
        <div css={AffiliatePageStyle.wrapT_C}>
          <h2 className="title">{t("affiliatePage:howjoinAff:title")}</h2>
          <div className="wrap-term">
            <p>{t("affiliatePage:howjoinAff:desc_1")}</p>
            <p>{t("affiliatePage:howjoinAff:desc_2")}</p>
            <p>{t("affiliatePage:howjoinAff:desc_3")}</p>
          </div>
        </div>
        <div css={AffiliatePageStyle.wrapT_C}>
          <h2 className="title">{t("affiliatePage:commisRelease:title")}</h2>
          <div className="wrap-term">
            <p>{t("affiliatePage:commisRelease:desc")}</p>
          </div>
        </div>
        <div css={AffiliatePageStyle.wrapT_C}>
          <h2 className="title">{t("affiliatePage:operating:title")}</h2>
          <div className="wrap-term">
            <p>{t("affiliatePage:operating:desc_1")}</p>
            <p>{t("affiliatePage:operating:desc_4")}</p>
            <p>{t("affiliatePage:operating:desc_5")}</p>
            <p>{t("affiliatePage:operating:desc_3")}</p>
          </div>
        </div>
        <div css={AffiliatePageStyle.wrapT_C}>
          <h2 className="title">{t("affiliatePage:netProfit:title")}</h2>
          <div className="wrap-term">
            <p>
              <strong>{t("affiliatePage:netProfit:subtitle_1")}</strong>
            </p>
            <p>{t("affiliatePage:netProfit:desc_1")}</p>
            <div className="note" style={{ margin: "10px 0 15px 15px" }}>
              {t("affiliatePage:netProfit:noteItem_1")}
            </div>
            <p>
              <strong>{t("affiliatePage:netProfit:subtitle_2")}</strong>
            </p>
            <p>{t("affiliatePage:netProfit:desc_2")}</p>
          </div>
        </div>
        <div css={AffiliatePageStyle.wrapT_C}>
          <h2 className="title">{t("affiliatePage:additional:title")}</h2>
          <div className="wrap-term">
            <p>{t("affiliatePage:additional:desc_1")}</p>
            <p>{t("affiliatePage:additional:desc_2")}</p>
            <p>{t("affiliatePage:additional:desc_3")}</p>
            <p>{t("affiliatePage:additional:desc_4")}</p>
          </div>
        </div>
        <div css={AffiliatePageStyle.wrapT_C}>
          <h2 className="title">{t("affiliatePage:termCond:title")}</h2>
          <div className="wrap-term">
            <p>{t("affiliatePage:termCond:desc_1")}</p>
            <p>{t("affiliatePage:termCond:desc_2")}</p>
            <p>{t("affiliatePage:termCond:desc_3")}</p>
            <p>{t("affiliatePage:termCond:desc_4")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AffiliatePage;
