/** @jsxImportSource @emotion/react */

import { message } from "antd";
import { retrieveApi } from "api/retrive";
import { BannerType } from "api/retrive/type";
import { SysLangLocal } from "contants/common";
import { ResponseCode } from "contants/response";
import {
  LegacyRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { BannerStyle } from "./index.style";
function Banner() {
  const navigate = useNavigate();

  const language = localStorage.getItem(SysLangLocal);

  const [banner, setBanner] = useState<BannerType[]>();

  const getBanner = async () => {
    const res = await retrieveApi.getBanner();

    if (res.status === ResponseCode.SUCCESS) {
      setBanner(res.data);
    } else {
      message.error(res.msg);
    }
  };

  const sliderRefAbove: MutableRefObject<Slider | null | undefined> = useRef();
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 150,
    autoplaySpeed: 3000,
    arrows: false,
    fade: true,
    ref: sliderRefAbove as LegacyRef<Slider> | undefined,
    dots: true,
  };

  useEffect(() => {
    getBanner();
  }, [language]);

  return (
    <div css={BannerStyle.index}>
      <Slider {...settings}>
        {banner?.map((item, index) => {
          return (
            <div css={BannerStyle.banner} key={`key-${index}-slider`}>
              <img
                onClick={() => (window.location.href = item.banner_url)}
                loading="lazy"
                src={item.web_banner}
                alt=""
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Banner;
