import "../i18n";

type affType = {
  player: string;
  deposit: string;
  cashout: string;
  winLoss: string;
  platformFee: string;
  bonus: string;
  paymentFee: string;
  commission: string;
};

export const dataAffiliate: affType[] = [
  {
    player: "A",
    deposit: "10000",
    cashout: "2000",
    winLoss: "8000",
    platformFee: "1440",
    bonus: "0",
    paymentFee: "356",
    commission: "3102",
  },
  {
    player: "B",
    deposit: "50000",
    cashout: "0",
    winLoss: "50000",
    platformFee: "9000",
    bonus: "0",
    paymentFee: "2225",
    commission: "19387.5",
  },
  {
    player: "C",
    deposit: "0",
    cashout: "0",
    winLoss: "0",
    platformFee: "0",
    bonus: "200",
    paymentFee: "0",
    commission: "-100",
  },
  {
    player: "D",
    deposit: "500",
    cashout: "0",
    winLoss: "500",
    platformFee: "90",
    bonus: "500",
    paymentFee: "22.25",
    commission: "-56",
  },
  {
    player: "E",
    deposit: "10000",
    cashout: "40000",
    winLoss: "-30000",
    platformFee: "0",
    bonus: "10000",
    paymentFee: "-1200",
    commission: "-19400",
  },
];
