/** @jsxImportSource @emotion/react */

import Footer from "components/Common/Footer";
import { Modal } from "components/Common/Modal";
import { Header } from "components/Desktop/ui/Header";
import { HeaderAffiliate } from "components/Desktop/ui/HeaderAffiliate";
import { PATH } from "contants/path";
import { Outlet, useLocation } from "react-router-dom";

import { DefaultLayoutStyle } from "./index.style";

export const DefaultLayout = () => {
  const location = useLocation();
  const affiliatePages = [PATH.affiliatePage, PATH.tc];

  const hasHeaderAffiliate = affiliatePages.some((page) =>
    location.pathname.includes(page)
  );

  return (
    <div css={DefaultLayoutStyle.self}>
      {hasHeaderAffiliate ? <HeaderAffiliate /> : <Header />}
      <main>
        <Outlet />
      </main>
      <Footer />

      <Modal />
    </div>
  );
};

export default DefaultLayout;
