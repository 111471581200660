/** @jsxImportSource @emotion/react */
import { notification } from "antd";
import {
  DataDetailCategoryType,
  LIST_DATA,
  ListDataType,
} from "contants/list-category-game";
import { handleLoginGame } from "helpers/handleLoginGame";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect, useRef, useState } from "react";
import { selectUsername } from "store/auth";
import { StatusNotification } from "types";

import { DropdownGameProviderStyle } from "./index.style";

type Props = {
  idCategoryGame: string;
  visible: boolean;
  handleRedirectSubGame: (
    idGame: string,
    path: string,
    providercode: string
  ) => void;
};
function DropdownGameProvider(props: Props) {
  const { idCategoryGame, visible } = props;

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
      key: "LoginGame",
      duration: 20,
    });
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dataType, setDataType] = useState<ListDataType>();

  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  useEffect(() => {
    if (idCategoryGame) {
      const dataProviderGame = LIST_DATA.find((i) => i.id === idCategoryGame);
      setDataType(dataProviderGame);
    }
  }, [idCategoryGame]);

  if (!dataType) return null;

  const dataGame = [...dataType?.data];

  const handleOnclickItemGame = (item: DataDetailCategoryType) => {
    if (
      dataType.id === "SB" ||
      dataType.id === "LC" ||
      item.providerCode === "AVIA"
    ) {
      handleLoginGame(
        {
          game_code: "",
          name: "",
          provider_code: item.providerCode,
          type: dataType.id,
          featured: "",
          new_arrive: "",
          picture: "",
        },
        username,
        dispatch,
        openNotification
      );
      return;
    }

    dataType.id === "CRUSH" &&
    dataType.data.filter((f) => f.providerCode === item.providerCode)
      ? props.handleRedirectSubGame("SL", dataType.path, item.providerCode)
      : props.handleRedirectSubGame(
          dataType.id,
          dataType.path,
          item.providerCode
        );
  };

  return (
    <div
      ref={dropdownRef}
      css={DropdownGameProviderStyle.container(visible)}
      className={`drop-game-${idCategoryGame}`}
    >
      {contextHolder}
      <div className="content" css={DropdownGameProviderStyle.content}>
        <div className="boxGame" css={DropdownGameProviderStyle.boxGame}>
          {dataGame.map((item, index) => {
            return (
              <div
                css={DropdownGameProviderStyle.itemGame}
                className="itemGame"
                key={`item-menu-header-${index}-${idCategoryGame}`}
                onClick={() => handleOnclickItemGame(item)}
              >
                <div className={`item-image`}>
                  <img src={item.providerImage} alt="..." />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DropdownGameProvider;
