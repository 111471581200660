export enum OrderIsReward {
  Pending = 0,
  Approve = 1,
  Reject = 2,
  Processing = 3,
}

export enum OrderStatus {
  Pending = 0,
  Sattlement = 1,
  Cancelled = 2,
  Entitlement = 3,
  Void = 4,
  Accomplish = 5,
}

export enum OrderType {
  DEPOSIT = 1,
  WITHDRAWAL = 2,
  REBATE = 3,
  REWARD = 4,
}

export enum DepositType {
  OFFLINE = 1,
  ONLINE = 2,
}

export enum StatusTransaction {
  ALL = 0,
  SUCCESS = 1,
  CANCEL = 2,
}
